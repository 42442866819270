import {fetchPost,fetchGet} from "@/router/https"

// 密码登录
export function passwordLogin(data){
    return fetchPost('/mallapp/userinfo/login',data)
}
// 发送验证码
export function getcode(param) {
  return fetchGet('/mallapp/phone/code',param);
}
// 注册  
export function beginRegister(data){
    return fetchPost('/mallapp/userinfo/register',data)
}
// 忘记密码  
export function updatePassWord(data){
    return fetchPost('/mallapp/userinfo/updatePassWord',data)
}
// 验证码登录
export function codeLogin(data){
    return fetchPost('/mallapp/userinfo/phone/login',data)
}

// 发送邀请码
export function sendQrCode (params) {
    return fetchGet('/mallapp/homepage/bindingInviteCode', params)
}

// 验证码登录
export function scanCode(data){
    return fetchPost('/createrapp/cloudInterests/scanCode',data)
}