<template>
	<div id="app">
		<van-nav-bar title="" left-arrow fixed placeholder :border="false" @click-left="onClickLeft" right-text="" />

		<div v-if="tabCur == 0">
			<p class="title">请输入手机号</p>
			<div class="input">
				<van-field v-model="form.phone" left-icon="iconfont1 icon-phone" placeholder="请输入您的手机号" />
			</div>

			<van-button type="primary" @click="next">下一步</van-button>
			<div class="footer">
				<van-checkbox v-model="checked"></van-checkbox> <span style="margin-left: 10px;">登录即表示您已详细阅读并同意</span>
				<span style="color: #FF0000;" @click="$router.push({path: '/userAgreement'})">《用户协议》</span> 与 <span
					style="color: #FF0000;" @click="$router.push({path: '/privacyPolicy'})">《隐私协议》</span>
			</div>
		</div>

		<div v-if="tabCur == 1">
			<p class="title" style="margin-bottom: 10px;">请输入短信验证码</p>
			<p style="font-size: 12px;margin-left: 3%;color: #999999;">验证码已发送至 <span style="color: #FB2B53;">+86
					{{form.phone}}</span></p>
			<div class="captcha">

				<input v-for="(c, index) in ct" :key="index" type="number" v-model="ct[index]" ref="input"
					:style="{borderBottomColor: index <= cIndex ? '#333' : ''}"
					@input="e => {onInput(e.target.value, index)}"
					@keydown.delete="e=>{onKeydown(e.target.value, index)}" @focus="onFocus" :disabled="loading">

			</div>

			<p style="margin: 3%;">{{msg}}秒后重新发送</p>
			<van-button type="primary" @click="submit">立即注册</van-button>
		</div>

		<!-- <div v-if="tabCur == 2">
			<p class="title" style="margin-bottom: 10px;">请输入邀请码</p>
			<p style="font-size: 12px;margin-left: 3%;color: #999999;margin-bottom: 30px;">手机号已验证通过！</p>
			<van-field v-model="form.phone" placeholder="请输入邀请码或邀请人手机号" />
			<van-button type="primary" @click="next">确认</van-button>

		</div> -->
	</div>
</template>

<script>
	import { Toast } from 'vant';
	import { getcode, beginRegister, sendQrCode, scanCode} from '@/api/login'
	export default {
		data() {
			return {
				form: {
					phone: null,
					code: null,
				},
				checked: false,
				tabCur: 0,
				ct: ['', '', '', ''],
				loading: false,
				msg: 60,
			}
		},
		computed: {
			ctSize() {
				return this.ct.length;
			},
			cIndex() {
				let i = this.ct.findIndex(item => item === '');
				i = (i + this.ctSize) % this.ctSize;
				return i;
			},
			lastCode() {
				return this.ct[this.ctSize - 1];
			}

		},
		watch: {
			cIndex() {
				this.resetCaret();
			},
			lastCode(val) {
				if (val) {
					this.$refs.input[this.ctSize - 1].blur();
					this.sendCaptcha();
				}
			}
		},

		mounted() {
			this.resetCaret();
		},
		created() {
			console.log(this.$route, '11111111111')
			this.$store.commit('setSession', this.$route.query.session ||
				"$2a$10$dRyM83yY7OdkJXIVIhGnYeB2HT1/4.fBSCX.FwSsiKWq5wbp5buZ6")
		},
		methods: {
			onClickLeft() {

				let u = navigator.userAgent;
				let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
				let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
				if (isIOS) {
					window.webkit.messageHandlers.close.postMessage("up");
				}
				if (isAndroid) {
					window.android.close();
				}
				// this.$router.go(-1)

			},
			next() {
				if (this.checked) {
					if (this.verify(this.form.phone)) {

						const that = this
						getcode({
							phone: this.form.phone,
							type: 2
						}).then(res => {
							if (res.data.code === 0) {
								this.tabCur += 1
								var num = 60
								var interval = setInterval(function() {
									num -= 1
									that.msg = num
									if (that.msg == 0) {
										clearInterval(interval)
										that.tabCur -= 1
										that.ct.forEach((item, n) => that.$set(that.ct, n, ''))
									}

								}, 1000)
							} else {
								Toast(res.data.message)
							}
						})

					} else {
						Toast.fail('请输入正确的手机号');
					}
				} else {
					Toast.fail('请详细阅读并同意《用户协议》 与 《隐私协议》');
				}


			},
			verify(val) {
				return /^1\d{10}$/.test(val)
			},
			onInput(val, index) {
				val = val.replace(/\s/g, '');
				if (index == this.ctSize - 1) {
					this.ct[this.ctSize - 1] = val[0]; // 最后一个码，只允许输入一个字符。
				} else if (val.length > 1) {
					let i = index;
					for (i = index; i < this.ctSize && i - index < val.length; i++) {
						this.ct[i] = val[i];
					}
					this.resetCaret();
				}
			},
			// 重置光标位置。
			resetCaret() {
				this.$refs.input[this.ctSize - 1].focus();
			},

			onFocus() {
				// 监听 focus 事件，将光标重定位到“第一个空白符的位置”。
				let index = this.ct.findIndex(item => item === '');
				index = (index + this.ctSize) % this.ctSize;
				console.log(this.$refs.input)
				this.$refs.input[index].focus();
			},
			onKeydown(val, index) {
				if (val === '') {
					// 删除上一个input里的值，并对其focus。
					if (index > 0) {
						this.ct[index - 1] = '';
						this.$refs.input[index - 1].focus();
					}
				}
			},
			sendCaptcha() {
				// this.msg = `发送验证码到服务器：${this.ct.join('')}`;
				// 此时无法操作 input。。
				console.log("kanakn")
				// this.loading = true;
				// setTimeout(() => {
				// 	this.msg = ('验证码错误')
				// 	this.loading = false;
				// 	this.$nextTick(() => {
				// 		this.reset();
				// 	})
				// }, 3000)

			},
			submit() {
				if (this.ct.join('').split('').length == 4) {
					this.form.code = this.ct.join('')
					beginRegister(this.form).then(res => {
						if (res.data.code === 0) {
							Toast.success("注册成功")
							sendQrCode({
                qrCode: this.$route.query.invite_code,
                userId: res.data.data.id,
								types: this.$route.query.types ? this.$route.query.types : null
              }).catch(() => {
								Toast('绑定失败，请重新扫码绑定')
							})

							scanCode({
                userId: res.data.data.id,
                parentId: this.$route.query.userId,
                type: this.$route.query.type
              })

							if (this.$route.query.type == 1) {
								this.openApp(
									"login?type=" + this.$route.query.type + "&phone=" +
									this.form.phone +
									"&password=" +
									this.form.password,
									true
								);
							}else{
								this.openApp(
									"login?type=" + this.$route.query.type + "&phone=" +
									this.form.phone +
									"&password=" +
									this.form.password +
									"&userId=" +
									this.$route.query.userId,
									true
								);
							}

						} else {
							Toast.fail(res.data.message);
						}
					})
				} else {
					Toast.fail('请输入验证码');
				}
			},
			reset() {
				// 重置。一般是验证码错误时触发。
				this.ct.forEach((item, n) => this.ct[n] = '');
				this.resetCaret();
			}
		},

	}
</script>

<style lang="less" scoped>
	.title {
		font-size: 28px;
		margin: 120px 3% 43px 3%;
	}

	.input .van-cell {
		background-color: #F8F8F8;
		border-radius: 18px;
		width: 96%;
		margin: 2%;
	}

	.van-button--primary {
		width: 94%;
		margin-left: 3%;
		height: 43px;
		background: #F52556;
		opacity: 1;
		border-radius: 22px;
		border: none;
		margin-top: 50px;

	}

	.footer {
		margin: 2%;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		line-height: 25px;
	}

	.captcha {
		display: flex;
		justify-content: space-around;
		margin-top: 40px;
	}

	.captcha input {
		margin-right: 20px;
		width: 45px;
		text-align: center;
		border: none;
		border-bottom: 4px solid #999999;
		font-size: 24px;
		outline: none;
	}

	.captcha input:last-of-type {
		margin-right: 0;
	}

	.captcha input:disabled {
		color: #000;
		background-color: #fff;
	}

	.msg {
		text-align: center;
	}

	/deep/ .van-field__left-icon .van-icon,
	.van-field__right-icon .van-icon {
		font-size: 20px;
		font-weight: bold;
		color: #999999;
	}

	/deep/ .van-field__control {
		font-size: 16px;
		margin-left: 8px;
	}
</style>
